import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'

import Logo from 'components/Icons/Logo'
import TicketBG from 'components/Icons/TicketBG'

import { getToken } from 'helpers/localStorage'

import { showSignModal } from 'store/Base/actions'

import LanguageSwitcher from '../LanguageSwitcher/index'

import s from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

function Header() {
	const [isScrolled, setIsScrolled] = useState(false)
	const [t] = useTranslation()
	const isAuth = !!getToken()
	const dispatch = useDispatch()
	const [isPhone, setIsPhone] = useState(window.innerWidth < 768)
	useEffect(() => {
		const handleResize = () => {
			setIsPhone(window.innerWidth < 768)
		}
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])
	const handleCreateEvent = useCallback(() => {
		const token = getToken()
		if (token) {
			dispatch(push('/dashboard'))
		} else {
			dispatch(showSignModal({ tab: 'login' }))
		}
	}, [dispatch])

	const handleScroll = () => {
		setIsScrolled(window.scrollY > 65)
	}
	useEffect(() => {
		window.onscroll = handleScroll
		return () => (window.onscroll = null)
	}, [])

	return (
		<Fragment>
			<header className={cx('Component', isScrolled && 'Shadow')}>
				<div className={cx('Container')}>
					<Link className={cx('Logo')} to="/">
						<Logo />
					</Link>
					<div className={cx('Actions')}>
						<LanguageSwitcher className={cx('LanguageSwitcher')} isShort={isPhone} />
						<button onClick={handleCreateEvent} className={cx('TicketButton')}>
							<TicketBG size={270} />
							<span>{isAuth ? t('user.myEvents') : t('event.createEvent')}</span>
						</button>
					</div>
				</div>
			</header>
		</Fragment>
	)
}

export default memo(Header)
