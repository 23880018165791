export default {
	Enter: 'Wejść',

	profile: 'Profil',
	account: 'Moje konto',

	general: 'Ogólne',
	finances: 'Finanse',
	infoFull: 'Twoje konto jest pełne',
	infoNotFull: 'Twoje konto jest niekompletne',
	editData: 'Edytuj dane',
	cashWithdrawal: 'Wypłata gotówki',
	withdrawMoney: 'Wypłacić pieniądze',
	uploadImageDescription:
		'Pobierz zeskanowane kopie dokumentów (paszport, numer identyfikacyjny, wyciągi ze zunifikowanego rejestru, zaświadczenie z banku o otwarciu konta)',
	uploadImageDescriptionNatural:
		'Pobierz zeskanowane kopie dokumentów (paszport, numer identyfikacyjny)',
	uploadImageFormat: 'Pliki muszą mieć formaty: jpeg, jpg, png',
	dragInDrop: 'Przeciągnij plik tutaj lub',
	dragInDropImage: 'Przeciągnij obraz tutaj lub',
	uploadImageDetail1:
		'skany paszportowe ze stron od 1 do 11, w tym strona rejestracji i następne puste miejsce (tak, aby dział finansowy mógł zobaczyć, że rejestracja jest ostatnią ze skanów)',
	uploadImageDetail2: 'skany muszą być rozłożone (2 strony na skan)',
	uploadImageDetail3:
		'skan wewnętrznego dowodu osobistego, który zamiast paszportu musi znajdować się po obu stronach',
	cashDescription:
		'Możesz wypłacić pieniądze na istniejące konta lub utworzyć nowe, klikając przycisk „dodaj nowe konto”',
	addNewWayPay: 'Dodaj nową metodę płatności',
	personInfo: 'Informacje osobiste',
	editDescription: 'Pola oznaczone gwiazdką są wymagane.',
	uploadFileWithPC: 'pobierz z komputera',
	reset: 'Resetowanie',
	refresh: 'Odświeżać',
	scanType: {
		PASSPORT: 'Paszport',
		PASSPORT_PE:
			'Kopia paszportu indywidualnego przedsiębiorcy (wszystkie rozkładówki, w których są zapisy i rozpowszechnienie rejestracji, nawet jeśli go nie ma)',
		PASSPORT_OOO:
			'Paszporty dyrektora osoby prawnej (str. 1-6, rejestracja i następna strona po niej)',
		PASSPORT_OO:
			'Paszporty dyrektora osoby prawnej (str. 1-6, rejestracja i następna strona po niej)',

		INN: 'Numer identyfikacyjny',
		INN_PE: 'Kopia NIP z FLP',
		INN_OOO: 'NIP dyrektora osoby prawnej twarze',
		INN_OO: 'NIP dyrektora osoby prawnej twarze',

		REGISTRY_REPORT_PE:
			'Wyciąg z Unified State Rejestr / zaświadczenie o rejestracji państwowej indywidualnego przedsiębiorcy',
		REGISTRY_REPORT_OOO: 'Wyciągi z USR / zaświadczenia o stanie. rejestracja osoby prawnej',
		REGISTRY_REPORT_OO: 'Wyciągi z USR / zaświadczenia o stanie. rejestracja osoby prawnej',

		BANK_REPORT_PE: 'Zaświadczenie bankowe o otwarciu rachunku bieżącego',
		BANK_REPORT_OOO:
			'Zaświadczenie o otwarciu rachunku rozliczeniowego z Banku (nowy wzór ze wskazaniem IBAN)',
		BANK_REPORT_OO:
			'Zaświadczenie o otwarciu rachunku rozliczeniowego z Banku (nowy wzór ze wskazaniem IBAN)',

		COMPANY_STATUTE_OOO: 'Karty osób prawnych twarze',
		COMPANY_STATUTE_OO: 'Karty osób prawnych twarze',
		ORG_STRUCTURE_OOO: 'Struktura własności',
		ORG_STRUCTURE_OO: 'Struktura własności',

		DIRECTOR_PROTOCOL_OOO: 'Protokół, a także zarządzenie o powołaniu dyrektora',
		DIRECTOR_PROTOCOL_OO: 'Protokół, a także zarządzenie o powołaniu dyrektora',
		FINANCE_REPORT_OO: 'Sprawozdanie finansowe za rok',
		FONDY_CONTRACT: ''
	},

	verificationType: {
		PENDING: 'w trakcie weryfikacji',
		CANCELED: 'nie potwierdzone',
		VERIFIED: 'potwierdzony'
	},
	finance: {
		PENDING: 'Konto jest testowane, proszę czekać',
		CANCELED: 'Konto nie zostało zaakceptowane, skontaktuj się z pomocą techniczną',
		VERIFIED: 'Konto zaakceptowane',
		rs: 'p / s',
		paymentOnАccount: 'Płatność na p / s',
		paymentOnCard: 'Płatność na karcie',
		companyName: 'Nazwa firmy płatniczej',
		mfo: 'MIF',
		edrpou: 'EDRPOU',
		creditCard: 'Karta kredytowa',
		bankAccountNumber: 'Numer konta bankowego',
		uploadPassport: 'Pobierz skan paszportu',
		uploadId: 'Pobierz identyfikator skanowania. liczby ',
		cardNumber: 'Numer karty',
		uploadContract: 'Pobierz skan podpisanej umowy z fondy',
		naturalPersons: 'Osoba fizyczna',
		naturalPersonsFull: 'Indywidualny',
		privateEntrepreneurFull: 'Entepreneur',
		oooFull: 'Spółka z ograniczoną odpowiedzialnością',
		ooFull: 'Organizacja publiczna',
		privateEntrepreneur: 'FLP',
		ooo: 'LLC',
		iban: 'IBAN',
		oo: 'SO',
		ltd: 'Ltd',
		statement: 'Skanuj fragmenty z jednego rejestru',
		sertificateFromBank: 'Pomoc z banku, aby otworzyć konto'
	},
	addNewFinance: 'Dodaj nowe konto',
	email: 'E-mail',
	firstName: 'Imię',
	lastName: 'Nazwisko',
	phone: 'Numer telefonu',
	birthday: 'Data urodzenia',
	changePassword: 'Zmień hasło',
	oldPassword: 'Stare hasło',
	newPassword: 'Nowe hasło',
	passwordChanged: 'Hasło zostało pomyślnie zmienione',
	myEvents: 'Moje wydarzenia'
}
