export default {
	organizerLanding: 'Как это работает?',
	company: 'Компания',
	aboutUs: 'О нас',
	team: 'Команда',
	career: 'Карьера',
	contacts: 'Контакты',
	contactInformation: 'Контактная информация',

	resources: 'Организаторам',
	terms: 'Условия использования',
	policy: 'Политика конфиденциальности',
	support: 'База Знаний',
	faq: 'FAQ',
	telegram: 'Связаться с нами в telegram',
	office: {
		title: 'Контакты',
		street: 'ул. Институтская 2а',
		city: 'Киев, Украина, 01001',
		address: 'Киев, Институтская 2а'
	}
}
