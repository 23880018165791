export default {
	financeDeleted: 'Financial account deleted',
	financeNotDeleted: 'Financial account not deleted',
	eventIsEmpty: 'This event does not exist or has not been published by the organizer',
	ups: 'Whoops ..... ',
	somethingWrong: 'something went wrong',
	integrationMinLength:
		'The code is too short. The minimum number of characters must be more than 5',
	inputFinance: 'Enter finance details',
	slugExists: 'This slug already exists',
	enterSlug: 'Enter slug',
	prevSlugNotAvailable: 'Please note that the old link to the event will not be available.',
	'slug exists': 'Sorry, you cannot save this slug, it is already taken',
	'User with this Alias already exists': 'User with this Alias already exists.',
	fillDateLocationName: 'Fill in the name of the venue and address',
	fillAllDateLocationName: 'Enter the venue name and address for all languages',
	attemptsError:
		'The number of authorization attempts has been exceeded. Try again in a few seconds',
	'Please upload image': 'Please upload an event image',
	'Please add phone number': 'Please add phone number',
	'Organizer with this Name already exists': 'A contact name with this name already exists',
	'Organizer with this Email already exists': 'The contact person with this email already exists',
	'Organizer with this Phone already exists':
		'The contact person with this phone number already exists',
	incorrectSmsCode: 'The code is incorrect, or it has expired',
	emailVerificateError:
		'Sorry, but the confirmation link is no longer active or has already been activated. Try again.',
	resetPasswordCodeError: 'Sorry, but the link is no longer active. Try again.',
	'Field is required': 'Required field',
	'Invalid credantails': 'Invalid credentials',
	'Invalid phone number': 'Invalid phone number',
	'Enter a valid email address': 'Invalid email address',
	'Password must have at least 8 characters': 'The password must contain at least 8 characters',
	'User with this Email already exists': 'The user with this email already exists',
	'User with this Phone already exists': 'A user with this phone number already exists',
	'You are already subscribed': 'You are already subscribed',
	'Incorrect old password': 'Invalid old password',
	'Incorrect coupon': 'Incorrect promo code',
	"End date can't be later than event's end date":
		'The end date cannot be later than the end date of the event (%{date})',
	'Start date cant be after end date': ' The start date cannot be later than the end date ',
	'You can’t set a date in the past': 'You cannot set a start date in the past',
	'You can’t set end date in the past': 'You cannot set an end date in the past',
	'You can’t set end date before start': 'You cannot set the end date to the start date',
	'Something went wrong, contact support': 'Something went wrong. Please contact support. ',
	'Incorrect code': 'Invalid code. Please scan the QR code again ',
	'You have not access, contact organizer':
		'You do not have permission to activate the ticket. Please contact the organizer ',
	'Attendee status is invalid': 'Cannot activate ticket. The ticket has the status: ',
	'You cannot added role to yourself': 'You cannot assign a role to yourself',
	'Invite already send': 'The invitation has already been sent',
	'Select available languages': 'Please select available languages',
	'Error occurred, check fields': ' An error has occurred. Please check the data entered.',
	'No more tickets': 'No more tickets',
	'Coupon is inactive': 'Promocode is inactive',
	'Please fill in all fields': 'Please fill in all fields',
	'Please fill in all fields all lang': 'Please fill in all fields for all languages',
	'Please add days': 'Please add a day',
	'Password contains invalid symbol': 'The password contains invalid characters',
	'Please fill mandatory fields for main lang':
		'Please fill in the required fields for the primary language',
	'Specify phone or email': 'Specify phone or email'
}
