import React, { FC, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SubscribeForm from 'components/SubscribeForm'
import IconInstagram from 'components/Icons/Instagram'
import IconFacebook from 'components/Icons/Facebook'

import IconPin from 'components/Icons/Pin'
import IconEmail from 'components/Icons/Email'
import { IconTelegram } from 'components/Icons/Telegram'

import { useStore } from 'helpers/useStore'

import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { socials } from 'constants/config'

const cx = classNames.bind(styles)

interface FooterProps {
	hideContacts?: boolean
	isShort?: boolean
	classNames?: any
	isWhite?: boolean
}

const Footer: FC<FooterProps> = ({ hideContacts, isShort, classNames, isWhite }) => {
	const [t] = useTranslation()
	const pathname = useStore((state) => state.router.location.pathname)
	const year = useMemo(() => new Date().getFullYear(), [])
	return (
		<footer
			className={cx('Component', {
				Component_white_bg: pathname === '/search' || pathname === '/dashboard' || isWhite
			})}>
			{!isShort && (
				<div className={cx('Main')}>
					<div className={cx('Container', classNames || '')}>
						<nav className={cx('Nav')}>
							{/*<h4>*/}
							{/*	<Link to="/organizers">{t('footer.resources')}</Link>*/}
							{/*</h4>*/}
							<Link to="/terms">{t('footer.terms')}</Link>
							<Link to="/privacy">{t('footer.policy')}</Link>
							<Link to="/support">{t('footer.support')}</Link>
							<Link to="/contact">{t('footer.contactInformation')}</Link>
							<Link to="/faq">{t('footer.faq')}</Link>
						</nav>
						<div className={cx('Contacts')}>
							<h4> {t('footer.office.title')}</h4>
							<address>
								<IconPin className={cx('IconPin')} />
								Estonia, Tallinn, Kesklinna linnaosa, <br /> Vesivärava tn 50-201, 10152
								{/* {t('footer.office.full_address')} */}
								{/* {t('footer.office.city')} */}
							</address>
							{!hideContacts && (
								<Fragment>
									{/*<a href="tel:+380509421693"><IconPhone/> +380509421693</a>*/}
									<a href={`mailto:${socials.email}`}>
										<IconEmail /> {socials.email}
									</a>
								</Fragment>
							)}
							<a href={socials.telegram} target={'_blank'}>
								{' '}
								{t('footer.telegram')}
							</a>
						</div>
						<div className={cx('Subscribe')}>
							<SubscribeForm />
							<div className={cx('Socials')}>
								{/* <a href={socials.instagram} target={'_blank'}>
									<IconInstagram />
								</a> */}
								<a href={socials.facebook} target={'_blank'}>
									<IconFacebook />
								</a>
								<a href={socials.telegram} target={'_blank'}>
									<IconTelegram />
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className={cx('Additional', classNames)}>
				<div className={cx('PaymentSystems')}>
					<div>
						<img src={require('assets/img/paymentSystems/master_card_logo.svg')} alt="" />
					</div>
					<div>
						<img src={require('assets/img/paymentSystems/visa_logo.svg')} alt="" />
					</div>
					{/* <div>
						<img src={require('assets/img/paymentSystems/fondy_logo.svg')} alt="" />
					</div> */}
				</div>
				<p>© Pro Money, {year}</p>
			</div>
		</footer>
	)
}

export default Footer
