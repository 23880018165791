import event from './en/event'

export default {
	...event,
	addOwnLetter: 'Pridėti savo raidę',
	nameOwnLetter: 'Laiško pavadinimas',
	locationOnMap: 'Vieta žemėlapyje:',
	needAddOrganizer: 'Jums reikia pridėti organizatorių',
	needActiveFinance: 'Norėdami skelbti, turite turėti aktyvią finansinę sąskaitą',
	descriptionOwnLetter: 'Laiško aprašymas',
	wantToAddDraft: 'Šis įvykis buvo paskelbtas.',
	isDraftToAdd: 'Galbūt norite jį paslėpti juodraščiuose?',
	timeWarningMessage:
		'Atkreipkite dėmesį, kad 00-00 yra naujos dienos pradžia, o 23-59 - dienos pabaiga',

	yesDraft: 'Taip, paslėpti',
	yesPublish: 'Taip, paskelbti',
	wantToAddPublish:
		'Šis įvykis nebuvo paskelbtas ir yra matomas tik tiems, kurie turi prieigą prie satchel.events.',
	isPublish: 'Galbūt norite paskelbti įvykį?',
	withoutScript: 'Nenaudokite žymės scenarijaus',
	addTicket: 'Pridėti bent vieną bilieto tipą prie skelbimo',
	participants: {
		labels: {
			orders: 'Užsakymai',
			addParticipant: 'Pridėti dalyvį',
			invoices: 'Išrašytų sąskaitų faktūrų sąrašas',
			emailParticipant: 'El. paštas dalyviui',
			name: 'Pavadinimas',
			firstCol: 'Pavadinimas',
			email: 'El. paštu',
			phone: 'Telefonas',
			ticketName: 'Bilieto pavadinimas',
			code: 'Kodas',
			ticketTypePrice: 'Kaina',
			createdFormat: 'Sukurta',
			statusTrans: 'Statusas',
			paymentType: 'Mokėjimo tipas',
			coupon: 'Kuponas'
		},
		filter: {
			all: 'Visi užsakymai',
			allEndOrders: 'Visi užbaigti užsakymai',
			allNotEndOrders: 'Visi nebaigti užsakymai',
			orderWithPromo: 'Užsakymas su reklaminiu kodu',
			orderAwait: 'Užsakymas laukia patvirtinimo'
		},
		searchPlaceholder: 'Užsakymo Nr., vardas, pavardė, el. pašto adresas, telefono numeris',
		filterDateFrom: 'Pasirinkite laikotarpį (nuo):',
		filterDateTo: 'Prieš:'
	},
	ticketsFieldsRequired:
		'Jei norite sukurti naują klausimą, grįžkite į skyrių "Bilietų kūrimas" ir nurodykite duomenų laukus: bilieto pavadinimą, kainą, visų tipų bilietams. ',
	beginsMoreEnds: 'Renginio pradžios data yra vėlesnė už renginio pabaigos datą.',
	beginsTimeMoreEnds: 'Renginio pradžios laikas yra vėlesnis už renginio pabaigos laiką.',

	PAYMENTS: {},
	promo: {
		addNewPromo: 'Naujo reklaminio kodo pridėjimas',
		addNewGroup: 'Pridėti grupės nuolaidą\n',
		sumSale: 'Nuolaidų suma\n',
		name: 'Pavadinimas',
		saleApply: 'Nuolaida taikoma perkant',
		from: 'nuo',
		to: 'iki',
		maxQuantityUnlimited: 'Didžiausias bilietų skaičius, kuriam taikoma nuolaida, neribojamas\n',
		or: 'arba',
		allTypes: 'Visi tipai',
		promoHasGroup: 'Šis akcijos kodas yra grupinis, nuolaida taikoma perkant iš',
		tickets: 'bilietai',
		dateActivate: 'Aktyvavimo data\n',
		dataDeactivate: 'Deaktyvavimo data\n',
		timeActivate: 'Aktyvavimo laikas\n',
		timeDeactivate: 'Deaktyvavimo laikas\n',
		promoUnlimited: 'Šis akcijos kodas neturi naudojimo apribojimų\n',
		thisPromoUse: 'Šį reklaminį kodą galima naudoti\n',
		time: 'laikas',
		nameDiscount: 'Pavadinimas',
		activeFrom: 'Galioja nuo',
		activeTo: 'Galioja iki',
		promoIsEmpty: 'Jūsų reklaminių kodų sąrašas yra tuščias',
		labels: {
			promo: 'Akcijos kodas',
			discount: 'Nuolaida',
			groupDiscount: 'Grupės nuolaida',
			isPresale: 'Išankstinis pardavimas',
			code: 'Akcijos kodas',
			prefix: 'Priešdėlis',
			ticketTypes: 'Bilieto tipas',
			validTo: 'Galioja iki',
			type: 'Tipas',
			activations: 'Kiekis',

			name: 'Pavadinimas',
			begins: 'Galioja nuo',
			baigiasi: 'Galioja iki',
			validFrom: 'Galioja'
		},
		validNone: 'Nenurodyta',
		f: 'Nuo',
		t: 'Iki'
	},
	fieldEmptyDay1: 'Jūs turite tuščius laukus! Spustelėkite',
	fieldEmptyDay2: ' ir užpildykite visus privalomus laukus (pažymėtus žvaigždute). ',
	fieldEmptyForMultilanguage:
		'Atkreipkite dėmesį, kad įvykiai vyksta keliomis kalbomis ir privalomi laukai turi būti užpildyti visomis kalbomis',

	uploadDocument: 'Įkelti dokumentus\n',
	orDropFileHere: 'Arba vilkite failus čia\n',
	downloadFile: 'Įkelti failą\n',
	invalidFile: 'Netinkamas failo formatas',
	incorrectFormat: 'Vaizdas turi būti jpeg, jpg, pdf formato',
	downloadDocument: 'Įkelti dokumentus',
	warningForQuestion:
		'Negalima išsaugoti naujo klausimo, neužpildžius visų privalomų laukų (pažymėtų žvaigždute).\n' +
		'\n' +
		'Galbūt pamiršote nurodyti kitos kalbos lauką "Klausimas"',
	youChoice: 'Jūs pasirinkote dieną',
	undoChange: 'Atšaukti pakeitimą',
	saveChange: 'Išsaugoti pakeitimą',
	promoIncorrect: 'Promo neteisingas',
	nameQuestion: 'Klausimo pavadinimas',
	actions: 'Act',
	events: 'Įvykiai',
	deleteContact: 'Ištrinti kontaktą',
	editContact: 'Redaguoti kontaktą',
	contactTitle: 'Kontaktiniai asmenys',
	responseFor: 'Atsakingas už',
	selectContact: 'Pasirinkti kontaktą',
	goodJob: 'Puikus darbas! Jūs jau beveik baigėte.',
	goodJobEditPage: 'Puikus darbas! Įrašykite pakeitimus.',

	goodJobDescription:
		'Suaktyvinkite renginį, valdykite ir stebėkite bilietų pardavimus, naudodami realaus laiko ataskaitas ir analizę.',
	additionalPhone: 'Papildomas telefono numeris',
	linkFacebook: 'Nuoroda į facebook',
	additionalContact: 'Papildomas kontaktas',
	addNewContact: 'Pridėti kontaktą',

	creatingEvent: 'Kurti įvykį',
	editEvent: 'Įvykio redagavimas',
	creatingEventDescription:
		'Įveskite informaciją apie renginį, užpildykite atitinkamus laukus ir spustelėkite mygtuką "Išsaugoti"',
	commonInfo: 'Bendra informacija',
	addImageEvent: 'Pridėti įvykio fono paveikslėlį',
	uploadImage: 'Įkelti paveikslėlį',
	recommendEventImage:
		'Rekomenduojamas dydis yra 665 x 350, failai turi būti formatų: jpeg, jpg, png',
	orDropHere: 'Arba vilkite paveikslėlį čia',
	dateAndLocation: 'Data ir vieta',
	oneLocation: 'Viena vieta visoms dienoms',
	eventOnline: 'Renginys internete',
	addDay: 'Pridėti dieną',
	day: 'Diena',
	selectDateStart: 'Įvykio data (pradžia)',
	selectTimeStart: 'Įvykio laikas (pradžia)',
	selectTimeEnd: 'Įvykio laikas (pabaiga)',
	selectTimeZone: 'Pasirinkite laiko juostą',
	settingFinance: 'Sąskaitos, skirtos pinigams išimti, nustatymas',
	ddmmyy: 'dd/mm/yy',
	createTicket: 'Sukurti bilietus',
	nameTicket: 'Bilieto pavadinimas',
	quantityTicket: 'KIEKIS',
	priceTicket: 'Kaina',
	actionsTicket: 'Veiksmai',
	ticketWithAgree:
		'Patvirtintas bilietas (bilietą galima įsigyti tik gavus organizatoriaus patvirtinimą)',
	simplePay: 'Pirkti kelis bilietus už vieną profilį',
	ticketColor: 'Bilieto spalva',
	hideSetting: 'Paslėpti nustatymus',
	selectStartProfit: 'Pardavimo pradžios data',
	selectEndProfit: 'Pardavimo pabaigos data',
	selectTimeStartProfit: 'Pardavimo pradžios laikas',
	selectTimeEndProfit: 'Pardavimo pabaigos laikas',
	registrationFormTitle: 'Registracijos forma',

	eventIsEmpty: 'Įvykių sąrašas tuščias',
	incorrectSymbol: 'Netinkamas simbolis!',
	from: 'nuo ',
	add: 'Pridėti',
	integrationModalTitle: 'Pridėti',
	inputGA: 'Įveskite savo Google Analytics kodą, kad gautumėte įvykių statistiką:',
	inputFP: 'Įveskite Facebook Pixel kodą, kad gautumėte įvykio statistiką:',
	moveFile: 'Vilkite failus čia arba',
	fileFormat: 'Failai turi būti šių formatų: Failai: jpeg, jpg, png',
	myLetter: 'Noriu, kad laiške būtų mano tekstas',
	popup: {
		messageFirst: 'Jūs sėkmingai užsiregistravote į renginį',
		messageSecond: 'Prašome patikrinti savo pašto dėžutę',
		messageThree: ': įeinantis, reklaminis, šlamštas'
	},
	errorMessage: {
		ticketsAreBought: 'Negalima ištrinti įvykio. PromoAndDiscount jau įsigytas.',
		eventDelete: 'Įvykis sėkmingai ištrintas.',
		eventDontDelete: 'Įvykis nebuvo ištrintas.'
	},
	registrationForm: {
		selectTypeQuestion: 'Pasirinkite klausimo tipą',
		typeText: 'Teksto laukas',
		typeOptions: 'Atsakymo parinktys',
		typeFiles: 'Failų laukas',
		description: 'Žvaigždute pažymėti klausimai, kuriuos reikės atsakyti pildant registracijos formą',
		modal: {
			title: 'Naujo klausimo pridėjimas',
			withVariant: 'su atsakymo variantais',
			withFile: 'su failo lauku',
			inputQuestion: 'Įveskite klausimą',
			selectTypeTickets: 'Pasirinkite bilieto tipą (-us)',
			requiredQuestion: 'Klausimas yra privalomas ir bus pažymėtas',
			inputOwnAnswer: 'Įveskite savo atsakymą'
		}
	},

	subscribe: 'Prenumeruoti satchel.events naujienlaiškį',
	create: 'Sukurti įvykį',
	duplicate: 'Dublikatas',
	created: 'Sukurtas įvykis',
	generatingPayment: 'Tikėtis, sukurti mokėjimo formą',
	edited: 'Įvykis pasikeitė',
	createEvent: 'Sukurti įvykį',
	isDraft: 'Projektas',
	drafts: 'Projektai',
	draft: 'Į Projektus',
	currency: 'Valiuta',
	published: 'Paskelbta',
	pastEvents: 'Praėję įvykiai',
	eventsArchive: 'archyvuoti įvykiai',
	modify: 'Renginio kabinetas',
	view: 'Peržiūrėti',
	preview: 'Peržiūra',
	publish: 'Publikuoti',
	description: 'Aprašymas',
	shading: 'Į įvykio vaizdą pridėti užtemdymą',
	addEventImg: 'Pridėti įvykio fono paveikslėlį',
	eventImgRecommended: 'Rekomenduojamas dydis: 665x350',
	starts: 'Įvykio pradžia',
	ends: 'Įvykio pabaiga',
	startsSales: 'Pardavimų pradžia',
	endsSales: 'Pardavimų pabaiga',
	title: 'Renginio Pavadinimas',
	location: 'Vieta',
	locationTitle: 'Vietovės Pavadinimas',
	locationDescription: 'Vietovės Aprašymas',
	dateAndTime: 'Data ir laikas',
	addInCalendar: 'Pridėti į kalendorių',
	shareEvent: 'Dalinkitės renginiu su draugais:',
	organizerName: 'Kontaktinis asmuo',
	organizerSpecialization: 'Kokia tema kreiptis',
	newOrganizer: 'Naujas kontaktinis asmuo',
	editOrganizer: 'Keisti kontaktą',

	addLogo: 'Pridėti logotipą',
	tickets: 'Bilietai',
	ticketsDelete: 'Bilietas sėkmingai ištrintas',
	isDeleteTicket: 'Ištrinti bilietą',
	organizerText:
		'Visą kitą informaciją apie savo organizaciją galite pridėti po sukūrimo arba galite',
	doItNow: 'padaryti dabar',
	users: 'vartotojai',
	ticketss: 'bilietai',
	ticketsTitle: 'Akcijos ir nuolaidos',
	registrationTitle: 'Registracijos forma',
	participantsTitle: 'Dalyviai',
	billingTitle: 'Atsiskaitymo informacija',
	integrationsTitle: 'Integration',
	statisticTitle: 'Statistika',
	accessTitle: 'Access rights',
	ticketsSold: 'Parduoti bilietai',
	totalIncome: 'Bendros pajamos internete',
	phoneNumber: 'Telefono numeris',
	totalAmount: 'Bendra suma:',
	agree: 'Sutinku',
	agreeUse: 'su naudojimo sąlygomis',
	agreeAnd: 'ir ',
	agreePolicy: 'privatumo politika',
	showOnMap: 'rodyti žemėlapyje',

	delete: 'Ištrinti įvykį',
	eventCart: {
		breadCrumb: {
			event: 'Įvykiai'
		},
		tabs: {
			all: 'Visi',
			active: 'Aktyvus',
			passed: 'Praeitis',
			draft: 'Projektas'
		},
		title: 'Įvykių valdymas',
		contactPerson: 'Kontaktiniai veidai',
		contact: 'Susisiekite su'
	},

	questions: {
		'First Name': 'Vardas ir pavardė',
		'Last Name': 'Pavardė',
		'Phone Number': 'Telefono numeris',
		Email: 'El. paštas'
	},
	attendees: {
		addForm: {
			title: 'Pridėti narį',
			selectTicketType: 'Pasirinkite bilieto tipą:',
			price: 'Mokama suma'
		},
		showResult: 'Peržiūrėti užsakymą',
		sendLetterAgain: 'Pakartotinai siųsti patvirtinimo el. laišką',
		sendAll: 'Siųsti visiems',
		send: 'siųsti',
		activate: 'Aktyvinti',
		deactivate: 'Deaktyvuoti',
		refund: 'Grąžinti pinigus',
		activation: 'Bilieto aktyvavimas',
		base: 'Dalyvių bazė',
		enterTextSms: 'Įveskite SMS tekstą',
		status: 'Statusas',
		price: 'Apmokėta',
		ticketPrice: 'Bilieto kaina',
		data: 'Data',
		questionnaire: 'Profilis',
		statuses: {
			UNFINISHED: 'Mokėjimo laiko limitas',
			DECLINED: 'Patvirtinimas atmestas',
			PAID: 'Sėkmingai apmokėta',
			REGISTERED: 'Užregistruota',
			ERROR: 'Mokėjimo klaida',
			DEACTIVATED: 'Deaktyvuota',
			ACTIVATED: 'Aktyvuota',
			AWAITING_PAYMENT: 'Laukiama mokėjimo',
			AWAITING_APPROVE: 'Laukiama patvirtinimo',
			ARCHIVED_UNFINISHED: 'Archyvuota'
		},
		filters: {
			ALL: 'Visi',
			WEEK: 'Paskutinė savaitė',
			TODAY: 'Šiandien',
			PAID: 'Apmokėta',
			DECLINED: 'Nesumokėta',
			APPROVE: 'Laukiama patvirtinimo'
		},
		actions: 'Veiksmai',
		approve: 'Patvirtinti',
		decline: 'Atliekos'
	},
	ticket: {
		inputInfo: 'Įveskite savo informaciją apie svečią toliau\n',
		important:
			'Svarbu: jei nepridėjote bilietų, renginys nemokamas ir neribojamas lankytojų skaičius',
		newType: 'Naujas bilieto tipas',
		free: 'nemokamas',
		name: 'Bilieto pavadinimas',
		data: 'Data',
		quantity: 'Kiekis',
		ticket: 'Bilietas',
		price: 'price',
		discount: 'Nuolaida',
		discountIncluded: 'Sveikiname, grupės nuolaida įskaičiuota, ji jus išgelbėjo',
		saved: 'Sveikiname, sutaupėte pasinaudoję akcijos kodu',

		moreTicket: 'Dar vienas bilietas',
		addCoupon: 'Pridėti reklaminį kodą',
		addDiscount: 'Pridėti grupės nuolaidą',

		addDescription: 'Pridėti aprašymą',
		simpleCoupon: 'Paprastas akcijos kodas',
		multipleCoupon: 'Keli reklaminiai kodai',
		promocode: 'Akcijos kodas',
		remainingTickets: 'Likę bilietai:',
		total: 'Iš viso:',
		end: 'Bilietai parduoti',
		salesEnd: 'Pardavimas baigtas',
		signupTickets: 'Registruoti bilietus:',
		buy: 'Pirkti',
		pay: 'Mokėti',
		reg: 'Užsiregistruoti',
		buyTicket: 'Pirkti bilietus',
		buyTickets: 'Pirkti bilietus:',
		advancedSettings: 'Išplėstiniai Nustatymai',
		withApprove: 'Patvirtinta',
		simplifiedPurchase: 'Įjungti supaprastintą pirkimą',
		uploadPicture: 'Įkelti paveikslėlį'
	},
	registration: {
		mainDescription: 'Aprašymas prieš registracijos formą',
		addQuestion: 'Pridėti klausimą',
		editQuestion: 'Redaguoti klausimą',
		newQuestion: 'Naujas klausimas',
		question: 'Klausimas',
		type: 'Tipas',
		required: 'Reikalingas klausimas',
		forSomeTypes: 'Bilietams',
		edit: 'Redaguoti',
		hint: 'Patarimas',
		create: 'Sukurti',
		simpleText: 'Input line',
		bigText: 'Teksto langelis',
		select: 'Sąrašas',
		dropdown: 'Paieškos Sąrašas',
		options: 'Atsakymo parinktys',
		addOption: 'Pridėti parinktį',
		questionPriority: 'Klausimo eilės numeris'
	},
	integrations: {
		text: 'Čia galite pridėti savo scenarijus',
		ga: 'Įveskite savo Google Analytics kodą, kad gautumėte statistinius duomenis apie įvykį',
		fp: 'Facebook Pixel'
	},
	access: {
		header: 'Čia galite valdyti prieigą prie kitų žmonių renginių',
		items: [
			'- Bendraorganizatorius (turi teisę redaguoti viską kaip steigėjas, bet negali ištrinti renginio).',
			'- Pardavėjas (turi prieigą prie renginio dalyvių sąrašo, gali įkelti į xlsx, csv) ir gali keisti Google Analytics ID.',
			'- Savanoriai (turi prieigą prie renginio dalyvių sąrašo ir bilietų aktyvavimo prie įėjimo į renginį dienomis).'
		],
		add: 'Pridėti asmenį',
		role: 'vaidmuo',
		roleType: {
			OPERATOR: 'Operatorius',
			CO_ORGANIZER: 'Bendraorganizatorius',
			SALES: 'Pardavimų Skyrius',
			VOLUNTEER: 'Savanoris'
		},
		statusType: {
			ADDED: 'Pridėta',
			INVITED: 'Pakviestas'
		},
		name: 'Vardas',
		phone: 'Telefonas',
		status: 'Status',
		user: 'naudotojas',
		actions: 'Veiksmai',
		invite: 'Kvietimas',
		edit: 'Redaguoti',
		remove: 'Pašalinti',
		save: 'Išsaugoti',
		cancel: 'Atšaukti'
	},
	billing: {
		createBilling: {
			text: 'Pirmiausia užpildykite savo',
			link: 'finansinė informacija',
			orWait: 'arba laukite, kol bus sukurtas patvirtinimas'
		},
		chooseBilling: 'Pasirinkite ir išsaugokite sąskaitą',
		hideAll: 'Paslėpti viską',
		showAll: 'Rodyti viską'
	},
	payment: {
		PENDING: 'Jūsų mokėjimas išsiųstas, laukite patvirtinimo.',
		OK: 'Sveikiname, jūsų mokėjimas priimtas!',
		FAILURE: 'Jūsų mokėjimas nepriimtas, kreipkitės į klientų aptarnavimo skyrių.',
		downloadTickets: 'Atsisiųsti bilietus',
		registratedSuccessful: 'Sveikiname, registracija baigta sėkmingai!',
		awaitingApprove:
			'Kai kurie jūsų bilietai turi būti patikrinti. Laukite laiško į jūsų nurodytą el. paštą '
	},
	creatingHint: 'Pirmiausia sukurkite renginį',
	save: 'Išsaugoti',
	organizerOf: 'Kontaktinis asmuo',
	mainSettings: {
		title: 'Pagrindiniai Nustatymai'
	},
	locationSettings: {
		title: 'Vietos Nustatymai'
	},
	languageSettings: {
		title: 'Kalbos Nustatymai',
		isMultilanguage: 'Daugiakalbis įvykis',
		selectAvailable: 'Pasirinkite galimas kalbas',
		selectDefault: 'Pasirinkite Pagrindinę Kalbą'
	},
	hint: {
		previous_text: 'Anksčiau įvesta: ',
		is_multilanguage_changed:
			'<0> Dėmesio! </0> \nJūs pakeitėte savo renginio daugiakalbystės atspalvius, todėl jūsų renginys buvo išsiųstas į juodraščius. \nPatikrinkite, ar registracijos formoje turite reikiamus renginių, bilietų ir klausimų vertimus. \n Tada vėl paskelbkite renginį.'
	}
}
