export default {
	intro: {
		header: 'Сервис для продажи билетов на ваши события',
		analytics: 'Аналитика\nи отчеты в телеграм бот',
		tickets: 'Проверка и\nактивация билетов',
		money: 'Вывод прибыли\nна карту',
		notifications: 'Сбор базы\nучастников',
		fb: 'Расширенная анкета \nрегистрации',
		promocode: 'Промокоды\nи сложные скидки'
	},
	howItWorks: {
		title: 'Как это работает?',
		items: [
			{
				header: 'Создавайте и настраивайте свои события',
				text:
					'Сервис позволяет создавать адаптивную страницу вашего события с разными типами билетов за считанные минуты. Также вы можете самостоятельно добавлять членов команды к администрированию и управлять их доступом к изменениям события. А наш телеграм бот высылает вам уведомление про покупку каждого билета 24/7.',
				list: ['Разные типы билетов', 'Телеграм бот', 'Неограниченное количество организаторов события']
			},
			{
				header: 'Продвигайте и рекламируйте свои ивенты',
				text:
					'Наша система позволяет создавать акции с промокодами и сложными скидками для продвижения вашего события. А также позволяет отслеживать откуда пришли покупатели, сохраняет список незавершенных платежей с контактами для обработки клиентов и автоматически напоминает про незавершенную покупку билета через 15 минут.',
				list: [
					'Создание промокодов и групповых скидок',
					'Подключение Google Analytics/Facebook Pixel/tksource',
					'Автоматическое отслеживание с какого сайта пришли покупатели'
				]
			},
			{
				header: 'Работайте со своей аудиторией',
				text:
					'У вас будет таблица участников со всеми нужными данными. Есть возможность добавить любые дополнительные поля в регистрационную форму. Например поля: "должность", "компания" и т.д. Также вы можете создать тип билета, который будет требовать вашего подтверждения учасника перед его получением.',
				list: [
					'Расширенная анкета регистрации',
					'Экспорт базы клиентов в csv и xlsx',
					'Возможность отбора учасников'
				]
			}
		]
	},
	pricing: {
		title: 'Ценовая политика',
		items: [
			{
				header: 'Бесплатно для организаторов мероприятий',
				text: 'Никаких затрат на настройку. Нет ежемесячной подписки'
			},
			{
				header: 'Бесплатные билеты на бесплатные мероприятия',
				text:
					'Используйте бесплатно любые функции для событий. Если мероприятие бесплатное, то мы тоже 😁'
			},
			{
				header: 'Низкая комиссия для платных ивентов',
				text: 'Суммарно снимается 5% (если стоимость билета 100 грн, то вы получаете 95 грн)'
			}
		]
	},
	aboutUs: {
		title: 'Лучший помощник для проведения мероприятий',
		text:
			'ProMoney – это платформа для организации продажи билетов, в которой мы учли лучший опыт проведения как небольших так и довольно крупных мероприятий (4500+ человек). Вы, как организаторы или покупатели билетов – это наша семья. Мы постоянно развиваем сервис и всегда рады узнать о ваших идеях и потребностях.'
	},
	advantages: {
		title: 'Преимущества',
		header: 'Преимущества работы с нами',
		items: [
			{
				header: 'Поддержка\n вас по всем вопросам работы сервиса',
				text: 'Мы сопровождаем организатора от начала продаж билетов и до последнего посетителя'
			},
			{
				header: 'Быстрый запуск\nпродажи билетов',
				text: 'Обычно запуск возможен от 1-2 часов до 3 банковских дней'
			},
			{
				header: 'Быстрое получение денег\nс продаж билетов',
				text: 'Организатор получает деньги в течении 3 банковских дней с момента покупки билета'
			}
		]
	},
	startCooperate: {
		header: 'Начните продавать билеты c ProMoney и заработайте больше денег!',
		btn: 'Начать сейчас'
	}
}
