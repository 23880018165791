export default {
	organizerLanding: 'Ogólne informacje',
	company: 'Firma',
	aboutUs: 'O nas',
	team: 'Zespół',
	career: 'Kariera',
	contacts: 'Kontakty',
	contactInformation: 'Informacje kontaktowe',

	resources: 'Do organizatorów',
	terms: 'Warunki użytkowania',
	policy: 'Polityka prywatności',
	support: 'Baza wiedzy',
	faq: 'FAQ',
	telegram: 'Skontaktuj się z nami przez telegram',

	office: {
		title: 'Kontakty',
		street: 'ul. Khreshchatyk, 10 ',
		city: 'Kijów, Ukraina, 01001',
		address: 'Kijów, Khreshchatyk, 10'
	},

	madeBy: 'made by'
}
