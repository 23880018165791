export default {
	organizerLanding: 'Pricing',
	company: 'Company',
	aboutUs: 'About us',
	team: 'team',
	career: 'Career',
	contacts: 'Contacts',
	contactInformation: 'Contact information',
	resources: 'Organizers',
	terms: 'Terms of Use',
	policy: 'Privacy Policy',
	support: 'Knowledge Base',
	faq: 'FAQ',
	telegram: 'Contact us on telegram',

	office: {
		title: 'Contacts',
		street: 'Shaul Harnam 6, 38 ',
		city: 'Estonia, Tallinn, 4977396',
		address: 'Petakh Tikva, Shaul Harnam 6, 38',
		full_address: 'Estonia, Tallinn, Kesklinna linnaosa,',
		full_address_2: 'Vesivärava tn 50-201, 10152'

	},

	madeBy: 'made by'
}
