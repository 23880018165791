export default {
	Enter: 'Įveskite',
	profile: 'Profilis',
	account: 'Mano paskyra',

	general: 'General',
	finances: 'Finansai',
	infoFull: 'Jūsų sąskaita užpildyta',
	infoNotFull: 'Jūsų sąskaita yra nepilna',
	editData: 'Redaguoti duomenis',
	cashWithdrawal: 'Grynųjų pinigų išėmimas',
	withdrawMoney: 'Išimti pinigus',
	uploadImageDescription:
		'Atsisiųsti skenuotas dokumentų kopijas (paso, asmens kodo, išrašų iš vieningo registro, pažymos iš banko apie sąskaitos atidarymą)',
	uploadImageDescriptionNatural: 'Atsisiųsti nuskenuotas dokumentų kopijas (pasas, asmens kodas)',
	uploadImageDetail1:
		'paso skenuoti puslapiai nuo 1 iki 11, įskaitant registracijos puslapį ir kitą tuščią puslapį (kad finansų tvarkytojas galėtų matyti, jog skenuotame puslapyje yra naujausia registracija)',
	uploadImageDetail2: 'skenuoti turi būti išsklaidyti (po 2 puslapius viename skene)',
	uploadImageDetail3:
		'vidinės asmens tapatybės kortelės, kuri vietoj paso turi būti iš abiejų pusių, nuskaitymas',
	uploadImageFormat: 'failai turi būti šių formatų: Įrašai turi būti: jpeg, jpg, png',
	cashDescription:
		'Galite išsiimti pinigus į esamas sąskaitas arba susikurti naują sąskaitą paspaudę mygtuką "pridėti naują sąskaitą"',
	addNewWayPay: 'Pridėti naują mokėjimo metodą',
	personInfo: 'Asmeninė informacija',
	editDescription: 'Žvaigždute pažymėti laukai yra privalomi.',
	dragInDrop: 'Vilkite failą čia arba',
	dragInDropImage: 'Vilkite paveikslėlį čia arba',

	uploadFileWithPC: 'Atsisiųsti iš kompiuterio',
	reset: 'Atstatyti',
	refresh: 'Atnaujinti',
	scanType: {
		PASSPORT: 'Pasas',
		PASSPORT_PE:
			'Individualaus verslininko paso kopija (visos skiltys, kuriose yra įrašų, ir registracijos skiltis, net jei jos nėra)',
		PASSPORT_OOO: 'Juridinio asmens vadovo pasas (1-6 skilt., registracija ir kita skilt. po jos)',
		PASSPORT_OO:
			'Juridinio asmens direktoriaus pasai (1-6 psl., registracija ir kitas puslapis po jos)',

		INN: 'Identifikavimo numeris',
		INN_PE: 'FLP TIN kopija',
		INN_OOO: 'Juridinio asmens direktoriaus TIN',
		INN_OO: 'Juridinio asmens direktoriaus TIN',

		REGISTRY_REPORT_PE:
			'Valstybės Įmonės Registrų Centro išrašas / individualaus verslininko valstybinės registracijos pažymėjimas',
		REGISTRY_REPORT_OOO: 'Išrašai iš VĮRC / valstybės pažymėjimas. juridinio asmens registracija',
		REGISTRY_REPORT_OO: 'Išrašai iš VĮRC / valstybės pažymėjimas. juridinio asmens registracija',

		BANK_REPORT_PE: 'Banko pažyma apie einamosios sąskaitos atidarymą',
		BANK_REPORT_OOO:
			'Banko pažyma apie einamosios sąskaitos atidarymą (naujas pavyzdys su IBAN nurodymu)',
		BANK_REPORT_OO: 'Banko einamosios sąskaitos atidarymo pažyma (naujas pavyzdys su IBAN nuoroda)',

		COMPANY_STATUTE_OOO: 'Juridinių asmenų įstatai',
		ORG_STRUCTURE_OOO: 'Nuosavybės struktūra',
		ORG_STRUCTURE_OO: 'Nuosavybės struktūra',

		DIRECTOR_PROTOCOL_OOO: 'Protokolas, taip pat įsakymas dėl direktoriaus paskyrimo',
		DIRECTOR_PROTOCOL_OO: 'Protokolas ir įsakymas dėl direktoriaus paskyrimo',
		FINANCE_REPORT_OOOO: 'Metų finansinė ataskaita',
		FONDY_CONTRACT: ''
	},
	verificationType: {
		PENDING: 'tikrinama',
		CANCELED: 'nepatvirtintas',
		VERIFIED: 'patvirtinta'
	},
	finance: {
		PENDING: 'Sąskaita peržiūrima, prašome palaukti',
		CANCELED: 'Sąskaita nepriimta, kreipkitės į palaikymo tarnybą',
		VERIFIED: 'Sąskaita priimta',
		rs: 'c/a',
		paymentOnАccount: 'Mokėjimas į sąskaitą',
		paymentOnCard: 'Mokėjimas į kortelę',
		companyName: 'Įmonės pavadinimas',
		mfo: 'MFO',
		edrpou: 'EDRPOU',
		creditCard: 'Kredito kortelė',
		cardNumber: 'Įveskite kortelės numerį',
		bankAccountNumber: 'Banko sąskaitos numeris',
		uploadPassport: 'Įkelkite paso skenavimą',
		uploadId: 'Įkelti tapatybės numerio nuskaitymą',
		uploadContract: 'Įkelkite su fondu pasirašytos sutarties skeną',
		naturalPersons: 'Natūralus asmuo',
		naturalPersonsFull: 'Fizinis asmuo',
		privateEntrepreneurFull: 'Verslininkas',
		oooFull: 'Ribotos atsakomybės bendrovė',
		ooFull: 'Viešoji organizacija',
		privateEntrepreneur: 'Privatus',
		ooo: 'LLC',
		oo: 'PO',
		iban: 'IBAN',
		ltd: 'LTD',
		statement: 'Skenuoti vieno registro išrašą',
		sertificateFromBank: 'Banko pažyma apie sąskaitos atidarymą'
	},
	addNewFinance: 'Pridėti naują sąskaitą',
	email: 'El. paštas',
	firstName: 'Vardas ir pavardė',
	lastName: 'Pavardė',
	phone: 'Telefonas',
	birthday: 'Gimtadienis',
	changePassword: 'Keisti slaptažodį',
	oldPassword: 'Senas slaptažodis',
	newPassword: 'Naujas slaptažodis',
	passwordChanged: 'Slaptažodis sėkmingai pakeistas.',
	myEvents: 'Mano įvykiai'
}
