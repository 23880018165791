import api from './api'
import error from './error'
import event from './event'
import footer from './footer'
import landing from './landing'
import languages from './languages'
import postersPage from './posters_page'
import searchPage from './search_page'
import sign from './sign'
import user from './user'

export default {
	sign,
	error,
	event,
	landing,
	user,
	languages,
	searchPage,
	postersPage,
	footer,
	whiteLabel: {
		title:
			'Teraz możesz uzyskać własną platformę sprzedaży biletów na wydarzenia za <strong>99 USD miesięcznie lub 999 USD rocznie</strong>.',
		readMore: 'Czytaj więcej'
	},
	showBy: 'Pokaż przez',
	show: 'Pokazać',
	copy: 'Kopiuj',
	copied: 'Skopiowano',
	aliasDescr1: 'Możesz określić unikalny alias i podać linki do osób, na Facebooku itp. tak jak:',
	aliasDescr2: 'Jest to wygodne, ponieważ ludzie będą widzieć tylko Twoje wydarzenia.',
	learnMore: 'Dowiedz się więcej',
	subscribeForm: {
		header: 'Otrzymuj wiadomości e-mail od ProMoney, aby być na bieżąco z aktualizacjami!',
		success: 'Dziękujemy za subskrybowanie 😁'
	},
	ticketActivation: {
		title: 'Aktywacja biletu',
		ticketCreated: 'Bilet wydany',
		order: 'Nakaz'
	},
	toHome: 'Dom',
	cancel: 'Anuluj',
	notifications: {
		smsSent: 'SMS wysłano pomyślnie',
		saved: 'Zapisane',
		error: 'Wystąpił błąd!'
	},
	verify: {
		sendEmailAgain: 'Ponownie wyślij e-mail',
		verificatePhone: 'Potwierdź numer telefonu',
		emailVerificated: 'Gratulacje, e-mail potwierdzony!',
		emailSended: 'Wysłano e-mail',
		email: 'Potwierdź swój adres e-mail',
		phone: 'Potwierdź swój numer telefonu'
	},
	changeLang: 'Zmień język',
	terms: {
		title: 'Zasady obsługi',
		tabs: {
			organizer: 'Dla organizatorów',
			customer: 'Dla kupujących'
		}
	},
	policy: {
		title: 'Polityka prywatności'
	},
	cookie: {
		text:
			'Korzystając z ProMoney, wyrażasz zgodę na naszą politykę dotyczącą plików cookie. <0> Dowiedz się więcej. </ 0> ',
		accept: 'Akceptuj'
	},
	send: 'Wyślij',
	remove: 'Usuń',
	download: 'Pobierz',
	weekDays: ['Pn', 'Wt', 'Śr', 'Cz', 'Piątek', 'Sob', 'Słońce'],
	...api
}
