import React, { Fragment, memo } from 'react'

import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { compose } from 'redux'

import { useQuery } from '@apollo/client'
import { ALL_EVENTS_FROM } from 'apollo/queries'

import EventCard from 'components/EventCard'
import Footer from 'components/Footer'
import Loader from 'components/Loader'

import { getToken } from 'helpers/localStorage'

import { showSignModal } from 'store/Base/actions'

import Button from '../Landing/Button'
import Header from '../Landing/Header/index'
import NotFound from '../NotFound/NotFound'

import styles from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

function OwnPosters({ openSignModal, match }) {
	const [t] = useTranslation()
	const alias = (match && match.params && match.params.alias) || null
	const { loading, error, data } = useQuery(ALL_EVENTS_FROM, { variables: { alias } })
	if (error) return <NotFound isEmpty />
	return (
		<Fragment>
			<Header />
			<main className={cx('Page')}>
				<section className={cx('Intro')}>
					<h1 className={cx('IntroTitle')}>{t('postersPage.description')}</h1>
					<div>
						<img src={require('assets/img/mascotte/tickets.svg')} alt="" />
					</div>
				</section>
				<section className={cx('EventSection')}>
					{loading && (
						<div className={cx('Loader')}>
							<Loader />
						</div>
					)}
					{data && data.allEventsFrom && !data.allEventsFrom.length && (
						<div>
							<h1 className={'mb30'}>
								<Trans i18nKey={'event.eventIsEmpty'} />
							</h1>
							<Button isLink={true} text="toHome" to="/" className={cx('EventBtn')} />
						</div>
					)}

					{!loading && data && (
						<ul className={cx('EventList')}>
							{data &&
								data.allEventsFrom &&
								data.allEventsFrom.map((event) => {
									if (!event) return
									return <EventCard key={event.id} {...event} />
								})}
						</ul>
					)}
				</section>
			</main>

			<Footer />
		</Fragment>
	)
}

const mapDispatch = (dispatch) => ({
	openSignModal: () => {
		const token = getToken()
		if (token) {
			dispatch(push('/dashboard'))
		} else {
			dispatch(showSignModal({ tab: 'login' }))
		}
	}
})

export default compose(connect(null, mapDispatch), withRouter)(memo(OwnPosters))
